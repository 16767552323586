import React from 'react'
import {Link} from "gatsby"
import {social} from "../data/SidebarData"
import "../styles/impressum.css"
import logo from "../data/logos/logo.svg"


const Impressum = () => {
  return (
    <div className="impressum">
      <div className="impressum-links-container">
        <div className="impressum-column">
          <Link to="/">
            <img src={logo} alt="" className="impressum-logo"/>
          </Link>
        </div>
        <div className="impressum-column">
          <Link className="sidebar-link" to="/">
           <h6>HOME</h6>          
          </Link>
          <Link className="sidebar-link" to="/about">
            <h6>ABOUT US</h6>          
          </Link>
          <Link className="sidebar-link" to="/ourwork">
            <h6>OUR WORK</h6>
          </Link>                    
          <Link className="sidebar-link" to="/contact">
            <h6>CONTACT US</h6>          
          </Link>
        </div>
        <div className="impressum-column">
          <Link className="sidebar-link" to="/services">
            <h6>SERVICES</h6>          
          </Link>
          <Link className="sidebar-link" to="/privacypolicy">
            <h6>PRIVACY POLICY</h6>
          </Link>
          <Link className="sidebar-link" to="/disclaimer">
            <h6>DISCLAIMER</h6>
          </Link>
        </div>
      </div>
      <div className="impressum-underline"></div>
      <div className="impressum-social-container">
      {social.map((link)=>{
          const {id,url,icon} = link
          return (
            <a href={url} key={id} target="_blank" rel="noreferrer"
              className="impressum-social-link">
                {icon}
            </a>
          )
        })}        
      </div>
      <p className="text-center">Copyright <span >©</span>  THREE DIVISION PICTURES {new Date().getFullYear()}. All rights reserved.</p>
      
    </div>
  )
}

export default Impressum
