import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import "../styles/ourwork.css";
import Aos from "aos";
import { Seo } from "../components/Seo";
import { StaticImage } from "gatsby-plugin-image";
import CardModal from "../components/CardModal";

const OurWork = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, disable: "mobile" });
  }, []);
  // START YOUR PROJECT
  const [position, setPosition] = useState(0);
  const [modalShow, setModalShow] = useState(false);

  // BUTTON
  const checkPosition = () => {
    setPosition(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", checkPosition);
    return () => {
      window.removeEventListener("scroll", checkPosition);
    };
  });

  const handleModal = () => {
    setModalShow(true);
  };
  return (
    <Layout>
      {position > 10 && <Link to="/contact">
        <button data-aos="fade-up" className="start-your-project"><h6>Start Your <br />Project</h6></button>
      </Link>}
      <Seo title="Three Division Pictures | Our Work" url="https://www.3dp.vision/ourwork" />
      {/* BLOCK - 1 */}

      <div className="block1-work">
        <div className="block1-work-container">
          <h1 data-aos="fade-down">OUR WORK</h1>
        </div>
      </div>

      {/* BLOCK - 2 */}

      <div className="container-fluid block2-work">
        <div className="block1-work-container text-uppercase">
          <h2 data-aos="fade-down">"Crafting captivating narratives that resonate deeply with audiences."</h2>
        </div>
      </div>

      {/* BLOCK - 3 */}

      <div className="block3-work">
        <div className="container-fluid px-lg-5">
          <div className="row p-2 d-flex justify-content-center" data-aos="fade-up">

            <div className="col-md-6 col-sm-6 col-xs-12 p-0">
              <div className="ourwork-card rounded-0" onClick={handleModal}>
                <div className="p-0 d-flex justify-content-center">
                  <StaticImage data-aos="fade-right" src="../data/images/12.jpg" alt="about" className="ourwork-card-img"
                               placeholder="blurred" />
                  <p className="ourwork-card-read-more">Read more</p>
                </div>
                <div className="ourwork-card-body p-2 text-uppercase">
                  <small className="">BLOODLINE</small>
                  <small className="">Short film</small>
                </div>
              </div>
            </div>

            {/*<div className="col-md-3 col-sm-6 col-xs-12 p-0">*/}
            {/*  <div className="ourwork-card rounded-0">*/}
            {/*    <div className="p-0 d-flex justify-content-center">*/}
            {/*      <StaticImage data-aos="fade-right" src="../data/images/1.jpg" alt="about" className="ourwork-card-img"*/}
            {/*                   placeholder="blurred" />*/}
            {/*      <p className="ourwork-card-read-more">Read more</p>*/}
            {/*    </div>*/}
            {/*    <div className="ourwork-card-body p-2 text-uppercase">*/}
            {/*      <small className="">Title 1</small>*/}
            {/*      <small className="">Lancia Stratos</small>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="col-md-3 col-sm-6 col-xs-12 p-0">*/}
            {/*  <div className="ourwork-card rounded-0">*/}
            {/*    <div className="p-0 d-flex justify-content-center">*/}
            {/*      <StaticImage data-aos="fade-right" src="../data/images/2.jpg" alt="about" className="ourwork-card-img"*/}
            {/*                   placeholder="blurred" />*/}
            {/*      <p className="ourwork-card-read-more">Read more</p>*/}
            {/*    </div>*/}
            {/*    <div className="ourwork-card-body p-2 text-uppercase">*/}
            {/*      <small className="">Title 1</small>*/}
            {/*      <small className="">Lancia Stratos</small>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="col-md-3 col-sm-6 col-xs-12 p-0">*/}
            {/*  <div className="ourwork-card rounded-0">*/}
            {/*    <div className="p-0 d-flex justify-content-center">*/}
            {/*      <StaticImage data-aos="fade-right" src="../data/images/3.jpg" alt="about" className="ourwork-card-img"*/}
            {/*                   placeholder="blurred" />*/}
            {/*      <p className="ourwork-card-read-more">Read more</p>*/}
            {/*    </div>*/}
            {/*    <div className="ourwork-card-body p-2 text-uppercase">*/}
            {/*      <small className="">Title 1</small>*/}
            {/*      <small className="">Lancia Stratos</small>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

          </div>
        </div>
      </div>

      <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Layout>
  );
};

export default OurWork;
