import React from 'react';
import {
  FaFacebook,
  FaHome,
  FaUserFriends,
  FaFolderOpen,
  FaCalendarAlt,
  FaWpforms,
  FaYoutube,
  FaInstagram,
  FaVimeoV
} from 'react-icons/fa';
export const links = [
  {
    id: 1,
    url: '/',
    text: 'Home',
    icon: <FaHome />,
  },
  {
    id: 2,
    url: '/about',
    text: 'About',
    icon: <FaUserFriends />,
  },
  {
    id: 3,
    url: '/services',
    text: 'Services',
    icon: <FaFolderOpen />,
  },
  {
    id: 4,
    url: '/ourwork',
    text: 'Our Work',
    icon: <FaCalendarAlt />,
  },
  {
    id: 5,
    url: '/contact',
    text: 'Contact',
    icon: <FaWpforms />,
  },
];

export const social = [
  {
    id: 1,
    url: 'https://www.facebook.com/the3DP',
    icon: <FaFacebook />,
  },
  {
    id: 2,
    url: 'https://www.youtube.com/channel/UCIsQWdXxisrFqnGWWLk2jeA',
    icon: <FaYoutube />,
  },
  {
    id: 3,
    url: 'https://www.instagram.com/the3dp_/',
    icon: <FaInstagram />,
  },
  {
    id: 4,
    url: 'https://vimeo.com/user136511869',
    icon: <FaVimeoV />,
  }
];
