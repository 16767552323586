import React, {useEffect} from 'react'
import Layout from "../components/Layout"
import "../styles/contact.css"
import Aos from "aos"
import {Seo} from "../components/Seo"

const Contact = () => {
  useEffect(()=>{
    Aos.init({duration: 2000, once:true,disable: 'mobile'})
  },[])
  return (
    <Layout>
      <Seo title="Three Division Pictures | Contact" url="https://www.3dp.vision/contact"/>

      {/* BLOCK 1 */}       

      <div className="block1-contact-us">
        <div data-aos="fade-down" className="block1-contact-us-container">
          {/* <h6><small>BEFORE AND AFTER</small></h6> */}
          <h1>CONTACT US</h1>          
        </div>
      </div>

      {/* BLOCK 2 */}

      <div className="container-fluid d-flex align-items-center justify-content-center flex-column block2-contact-us">
        <div className="container d-flex justify-content-center align-items-center flex-wrap my-3">
          <div data-aos="fade-right" className="row d-flex justify-content-center">
            <div className="col-12 text-center m-2">
              <h6>PHONE</h6>
              <p>+385 97 698 2243</p>
            </div>
          </div>
          <div data-aos="fade-left" className="row d-flex justify-content-center">
            <div className="col d-flex justify-content-center text-center flex-column align-items-center m-2">
              <h6>EMAIL</h6>
              <a href="mailto:hello@3dp.vision" className="contact-link">
                <p className="contact-link">hello@3dp.vision</p>
              </a>              
            </div>
          </div>                   
        </div>
      </div>
    </Layout>
  )
}

export default Contact
