import React, {useState} from 'react'
import {Link} from "gatsby"
import { social, links } from '../data/SidebarData';
import logo from "../data/logos/logo.svg"
import "../styles/sidebar.css"

const Sidebar = ({position}) => {
  const [sidebar, setSidebar] = useState(false)
  return (
    <>
      {/* NAVBAR */}
      
      <nav className={`${position > 0 && !sidebar ? "navbar-custom-scroll" : "navbar-custom"}`}>
        <Link to="/">
          <img src={logo} alt="three division pictures" className={sidebar ? "invisible" : "logo m-md-3"}/>
        </Link>
        <div role="button" className={`${sidebar ? 'menu-btn open bg-transparent mr-lg-4 mr-md-4' : 'menu-btn mr-lg-4 mr-md-4'}`} onClick={()=>setSidebar(!sidebar)}>
          <div className="menu-btn__burger"></div>
        </div>
      </nav>

      {/* SIDEBAR */}

      <aside className={`${sidebar ? 'sidebar show-sidebar' : 'sidebar'}`}>
          <img src={logo} alt="three division pictures" className="sidebar-logo"/>
          <div className="sidebar-links">
            <ul className="links">
            {links.map((link)=>{
              const {id,url,text} = link
              return (
                <>
                   <li className="sidebar-li" key={id}>
                      <Link to={url}
                    className="sidebar-link" onClick={()=>setSidebar(false)} role="link">
                    <h1>{text}</h1></Link>
                   </li>
                </>
              )
            })}
            </ul>
          </div>
          <div className="social-container">
          <ul className="social-icons">
              {social.map((link) => {
                const {id,url,icon} = link
                return (
                  <a href={url} key={id} target="_blank" rel="noreferrer"  
                  className="social-link">
                    {icon}
                  </a>
                )
              })}
            </ul>
          </div>
        

      </aside>
      
    </>
  )
}

export default Sidebar
