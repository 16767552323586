import React, {useState, useEffect} from 'react'
import Layout from "../../components/Layout"
import {Link} from "gatsby"
import "../../styles/production.css"
import development from "../../data/icons/development-lijevo.svg"
import preproduction from "../../data/icons/pre-production-lijevo.svg"
import production from "../../data/icons/production-desno.svg"
import postproduction from "../../data/icons/post-production-desno.svg"
import Aos from "aos"
import {Seo} from "../../components/Seo"

const Production = () => {
  const [text1, setText1] = useState(true)
  const [text2, setText2] = useState(true)
  const [text3, setText3] = useState(true)
  const [text4, setText4] = useState(true)

  // const[button1, setButton1] = useState(false)
  // const[button2, setButton2] = useState(false)
  // const[button3, setButton3] = useState(false)
  // const[button4, setButton4] = useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`){
      var width = window.innerWidth
      if(width < 850){
        // setButton1(true)
        // setButton2(true)
        // setButton3(true)
        // setButton4(true)
      } if(width > 850) {
        setText1(true)
        setText2(true)
        setText3(true)
        setText4(true)
      }
    }    
  },[])
  useEffect(()=>{
    Aos.init({duration: 2000, once:true, disable: 'mobile'})
  },[])
  // START YOUR PROJECT
  const [position, setPosition] = useState(0)

  // BUTTON
  const checkPosition = () => {
    setPosition(window.pageYOffset)
  }
  useEffect(() => {
    window.addEventListener("scroll", checkPosition)
    return () => {
      window.removeEventListener("scroll", checkPosition)
    }
  })
  return (
    <>
    {position > 10 && <Link to="/contact"><button data-aos="fade-up" className="start-your-project"><h6>Start Your <br/>Project</h6></button></Link>}
    <Layout>
      <Seo title="Three Division Pictures | Production Services" url="https://www.3dp.vision/services/production"/>
      {/* BLOCK - 1 */}
      <div className="block1-production">
        <h2 data-aos="fade-down" className="py-5">Film and Video Production Services
        </h2>
        <p data-aos="fade" className="container">Our talented production team will find a solution for your vision. <br/> Together, we’ll develop your project from script to screen and make sure your ideas come to life.</p>
        <Link to="/services/marketing" className="Link-link mt-lg-5">
          <button data-aos="fade" className="services-button mb-4">BOOST<br/> YOUR PROJECT</button>
        </Link>
      </div>

    {/* BLOCK - 2 */}

    <div className="block2-production pb-lg-5">
      <div className="production-box">

        <div data-aos="fade-right" className="icon-box">
          <img src={development} alt="development foundation"/>  
          <h3 className="mt-lg-4">DEVELOPMENT</h3>        
          {text1 && <p>We’ll start off by building a strong foundation for your content through briefing, script-writing, budget preparation, and process evaluation. You’ll have complete oversight throughout each step of the process to ensure you feel confident and in-charge.</p>}
          {/* {button1 ? <button className="solutions-button" onClick={() => setText1(!text1)}>{text1 ? "Show less" : "Show more"}</button> : null} */}
        </div>
        
        <div data-aos="fade-right" className="icon-box">
          <img className="pt-lg-3" src={preproduction} alt="pre-production foundation"/>
          <h3>PRE PRODUCTION</h3>
          {text2 && <p>Our team will track down the most talented cast, an ideal location, and the best crew to bring your story to life. We’ll plan every step of the production process down to the finest detail for a flawless execution.</p>}
          {/* {button2 ? <button className="solutions-button" onClick={() => setText2(!text2)}>{text2 ? "Show less" : "Show more"}</button> : null} */}
        </div>

      </div>
      <div className="production-box">

        <div data-aos="fade-left" className="icon-box">
          <img src={production} alt="production"/>
          <h3>PRODUCTION</h3>
          {text3 && <p>Here’s where the real fun begins! We’ll start the physical production of your content, employing our filmmaking expertise to make sure everything comes to life exactly how you envisioned and planned.</p>}
          {/* {button3 ? <button className="solutions-button" onClick={() => setText3(!text3)}>{text3 ? "Show less" : "Show more"}</button> : null} */}
        </div>

        <div data-aos="fade-left" className="icon-box">
          <img className="pt-lg-5" src={postproduction} alt="post-production"/>
          <h3 className="pt-lg-4">POST PRODUCTION</h3>
          {text4 && <p>After wrapping up the filming, our talented team of editors will get to work making your content look, sound, and feel professional. We implement color grading, sound and picture editing, design, and other post production services. </p>}
          {/* {button4 ? <button className="solutions-button" onClick={() => setText4(!text4)}>{text4 ? "Show less" : "Show more"}</button> : null} */}
        </div> 

      </div>
    </div>

    {/* BLOCK - 3 */}

      <div className="block3-production">
        <div className="block3-production-container">
          <h1 data-aos="fade-down" className="text-uppercase">"We’ll help you tell your story <br/>to the right audience."</h1>
          <Link to="/contact" className="Link-link">
            <button data-aos="fade-up" className="about-us-button">contact us</button>
          </Link>
        </div>
      </div>
    </Layout>
    </>
  )
}

export default Production
