import React from 'react'

const NotFound = () => {
  return (
      <div className="bg-dark text-center">
        <h2>404</h2>
        <p>Sorry, that page doesn't exist</p>
      </div>
  )
}

export default NotFound
