import React from 'react'
import {Seo} from "../components/Seo"
import Layout from "../components/Layout"
import "../styles/disclaimer.css"

const Disclaimer = () => {
  return (
    <Layout>
      <Seo title="Three Division Pictures | Disclaimer" url="https://www.3dp.vision/disclaimer"/>
      <div className="disclaimer-block1 text-center">
        <div className="container-fluid d-flex flex-column align-items-center justify-content-center text-center">
          <div className="row">
            <h1 className="m-5 pt-3">DISCLAIMER</h1>
          </div>
          <p className="row">
                      The content of this website is copyright protected.
            In accordance with Croatian and international copyright laws, the content of this website is forbiden to process, reproduce, distribute or make available to third persons in any form, without a prior written authorization.
          </p>
          <p className="my-5">Copyright <span>©</span> {new Date().getFullYear()} Karlo Katulic. All rights reserved.</p>
          <p className="text-center">Still photography provided by: <span>©</span>Nikola Predovic and <span>©</span>MP film production</p>
        </div>
      </div>
    </Layout>
  )
}

export default Disclaimer
