import React, {useEffect, useState} from 'react'
import Layout from "../components/Layout"
import {Link} from "gatsby"
import "../styles/about.css"
import Aos from "aos"
import {Seo} from "../components/Seo"
import { StaticImage } from "gatsby-plugin-image"

const About = () => {
  useEffect(()=>{
    Aos.init({duration: 2000, once:true,disable: 'mobile'})
  },[])
  // START YOUR PROJECT
  const [position, setPosition] = useState(0)

  // BUTTON
  const checkPosition = () => {
    setPosition(window.pageYOffset)
  }
  useEffect(() => {
    window.addEventListener("scroll", checkPosition)
    return () => {
      window.removeEventListener("scroll", checkPosition)
    }
  })
  return (
    <>
    {position > 10 && <Link to="/contact"><button data-aos="fade-up" className="start-your-project"><h6>Start Your <br/>Project</h6></button></Link>}
    <Layout>
      <Seo title="Three Division Pictures | About Us" url="https://www.3dp.vision/about"/>
      {/* BLOCK - 1 */}

      <div className="block1-about-us">
        <div data-aos="fade-down" className="block1-about-us-container">
          <h1>ABOUT US</h1>          
        </div>
      </div>

    {/* BLOCK - 2 */}
    <div className="block2-about">
      <div className="about-img-container">
        <StaticImage data-aos="fade-right" src="../data/images/5.jpg" alt="about" className="about-img-div-1" placeholder="blurred"/>
        <StaticImage data-aos="fade-right" src="../data/images/7.jpg" className="about-img-div-2" alt="about" placeholder="blurred"/>
      </div>
      <div data-aos="fade-left" className="about-text-container">
        <h2 className="text-uppercase">Story is our passion,<br/> creation is our mission,<br/> and content our voice.</h2>
        <p>Three Division Pictures is a film production company passionate about helping talents and businesses bring their visions to life through the medium of film. We’re a team of creative professionals brought together by our affinity for filmmaking.</p>
        <p className="mt-0">Our talented team works with artists, brands, and other filmmakers to produce a variety of content including films, commercials and branded content. What sets us apart from the competition is our unrelenting dedication to quality. From the first moment we sit down with our clients, our team is unwaveringly dedicated to the success of your project. Welcome to the home of the next generation filmmakers.</p>
      </div>
    </div>

    {/* BLOCK - 3 */}

    <div className="block3-about">
      {/*<div className="block3-about-title">*/}
      {/*  <h1 data-aos="fade-down">THE CREW</h1>*/}
      {/*</div>*/}
      {/*<div className="block3-portraits">*/}
      {/*  <div data-aos="fade-right" className="portrait-1">*/}
      {/*    <div className="portrait-div-img-1">*/}
      {/*      <p className="px-2">Karlo is passionate about expressing and capturing emotions through film. He developed his craft by working on various international productions for some big-name players including Netflix and the BBC. At Three Division Pictures, he specializes in combining his knowledge of filmmaking and his business savvy. </p>*/}
      {/*    </div>*/}
      {/*    <h4>KARLO KATULIC</h4>*/}
      {/*    <h5>Founder & Producer</h5>*/}
      {/*  </div>*/}
      {/*  <div data-aos="fade" className="portrait-2">*/}
      {/*    <div className="portrait-div-img-2"><p className="px-2">His extensive marketing work has taken him to various parts of EMEA over the last decade. With Three Division Pictures, he leverages his deep understanding of the psychology of consumerism to generate the most effective strategies for driving leads and sales to your product or service. <span className="invisible">qwertzuiopšđžćčlkjhgfds</span> </p></div>*/}
      {/*    <h4>RAMI GAMAL</h4>*/}
      {/*    <h5>Commercial Director</h5>*/}
      {/*  </div>*/}
      {/*  <div data-aos="fade-left" className="portrait-3">*/}
      {/*  <div className="portrait-div-img-3"><p className="px-2"><div className="span invisible">with an art form, Alen pursued a career</div> To combine his technical prowess with an art form, Alen pursued a career in cinematography and storytelling. With an eye for the profound and meaningful in life, he brings a unique style to his work in TV shows, commercials, and movies. <span className="invisible">To combine his technical prowess  </span></p></div>*/}
      {/*    <h4>ALEN TURIC</h4>*/}
      {/*    <h5>Cinematographer</h5>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>

    {/* BLOCK - 4 */}

      <div className="block4-about">
        <div className="block4-about-container">
          <h1 data-aos="fade-down" className="text-uppercase">"Bring your vision to life <br/>for the world to see."</h1>
          <Link className="Link-link" to="/contact">
            <button data-aos="fade-up" className="about-us-button">contact us</button>          
          </Link>
        </div>
      </div>
    </Layout>
    </>
  )
}

export default About
