import React, {useState, useEffect} from 'react'
import Layout from "../../components/Layout"
import {Link} from "gatsby"
import "../../styles/marketing.css"
import seo from "../../data/icons/seo.svg"
import people from "../../data/icons/people-3.svg"
import cja from "../../data/icons/customer-journey-analysis.svg"
import brushes from "../../data/icons/brushes.svg"
import Aos from "aos"
import {Seo} from "../../components/Seo"

const Marketing = () => {
  const [text1, setText1] = useState(true)
  const [text2, setText2] = useState(true)
  const [text3, setText3] = useState(true)
  const [text4, setText4] = useState(true)

  // const[button1, setButton1] = useState(false)
  // const[button2, setButton2] = useState(false)
  // const[button3, setButton3] = useState(false)
  // const[button4, setButton4] = useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`){
      let width = window.innerWidth
      if(width < 850){
        // setButton1(true)
        // setButton2(true)
        // setButton3(true)
        // setButton4(true)
      } if(width > 850) {
        setText1(true)
        setText2(true)
        setText3(true)
        setText4(true)
      }
    }
  },[])
  useEffect(()=>{
    Aos.init({duration: 2000, once:true, disable: 'mobile'})
  },[])
  // START YOUR PROJECT
  const [position, setPosition] = useState(0)

  // BUTTON
  const checkPosition = () => {
    setPosition(window.pageYOffset)
  }
  useEffect(() => {
    window.addEventListener("scroll", checkPosition)
    return () => {
      window.removeEventListener("scroll", checkPosition)
    }
  })
  return (
    <Layout>
      {position > 10 && <Link to="/contact"><button data-aos="fade-up" className="start-your-project"><h6>Start Your <br/>Project</h6></button></Link>}
      <Seo title="Three Division Pictures | Marketing Services" url="https://www.3dp.vision/services/marketing"/>
      {/* BLOCK - 1 */}
      <div className="block1-marketing">
        <div className="block1-marketing-text-container">
          <h2 data-aos="fade-down" className="py-5">Marketing Services
          </h2>
          <p data-aos="fade" className="container">At the end of the day, we’re a content creation company. <br/>We know what it takes to get your video production in front of the right audience.</p>
          <Link to="/services/production" className="Link-link">
            <button data-aos="fade" className="services-button mb-4">VIDEO <br/>PRODUCTION</button>
          </Link>
        </div>
      </div>

    {/* BLOCK - 2 */}

    <div className="block2-marketing pb-lg-5">
      <div data-aos="fade-right" className="marketing-box">
        <div className="icon-box">
          <img src={seo} alt=""/>
          <h3>SEO <br/><span className="invisible">SOME</span></h3>
          {text1 && <p>Our experienced digital marketing team will position your video to perform well on Google and other search engines. By boosting SEO, we’re increasing the chances potential customers come across your content via organic search. <br/><p className="invisible">via organic search.</p></p>}
          {/* {button1 ? <button className="solutions-button" onClick={() => setText1(!text1)}>{text1 ? "Show less" : "Show more"}</button> : null} */}
        </div>
        <div className="icon-box">
          <img src={people} alt=""/>
          <h3 className="pt-lg-4">SOCIAL MEDIA <br/>MARKETING</h3>
          {text2 && <p>As a talented social media marketing company, we’ll spruce-up your business’s online presence on various social platforms through graphic design and other services. We’ll ensure your content continues to generate interest and conversations through the power of social media.</p>}
          {/* {button2 ? <button className="solutions-button" onClick={() => setText2(!text2)}>{text2 ? "Show less" : "Show more"}</button> : null} */}
        </div>
      </div>
      <div data-aos="fade-left" className="marketing-box">
        <div className="icon-box">
          <img src={cja} alt=""/>
          <h3 className="pb-md-4">CUSTOMER <br/>JOURNEY <br/>ANALYSIS</h3>
          {text3 && <p>To boost conversion rates and improve user experience, we’ll analyze how the average customer engages with your content and make changes accordingly. This makes it easier to optimize the user experience for maximum results.</p>}
          {/* {button3 ? <button className="solutions-button" onClick={() => setText3(!text3)}>{text3 ? "Show less" : "Show more"}</button> : null} */}
        </div>
        <div className="icon-box">
          <img src={brushes} alt=""/>
          <h3 className="pb-md-5">BRANDING</h3>
          {text4 && <p>Your content is only a small portion of your business’s overall identity. We can help create, develop, and promote your company’s branding through visual identity and graphic design. Having a unique image can help increase brand recognition and awareness.</p>}
          {/* {button4 ? <button className="solutions-button" onClick={() => setText4(!text4)}>{text4 ? "Show less" : "Show more"}</button> : null} */}
        </div>      
      </div>
    </div>

    {/* BLOCK - 3 */}

      <div className="block3-marketing">
        <div className="block3-marketing-container">
          <h1 data-aos="fade-down" className="text-uppercase">"Telling stories and connecting brands with people."</h1>
          <Link to="/contact" className="Link-link">
            <button data-aos="fade-up" className="about-us-button">contact us</button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default Marketing
