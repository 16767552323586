import React, {useEffect, useState} from "react"
import {Link} from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/home.css"
import Layout from "../components/Layout"
import Carousel from 'react-bootstrap/Carousel'
import slide1 from "../data/images/2.jpg"
import slide2 from "../data/images/6.jpg"
import slide3 from "../data/images/4.jpg"
import slide4 from "../data/images/3.jpg"
import slide5 from "../data/images/1.jpg"
import Aos from "aos"
import {Seo} from "../components/Seo"
// import IntroVideo from "../../static/Header Video.mp4"
import { StaticImage } from "gatsby-plugin-image"

export default function Home() {
  const [position, setPosition] = useState(0)
  // const [intro, setIntro] = useState(false)  
  // const [played, setPlayed] = useState(false)

  useEffect(()=>{
    Aos.init({duration: 2000, once:true, disable: 'mobile'})
  },[])

// VIDEO INTRO LOGIC !
  // useEffect(()=>{
  //   if(sessionStorage.getItem("Intro State") !== "PLAYED" && window.innerWidth > 1024){
  //     sessionStorage.setItem("Intro State", "PLAYED") 
  //     setIntro(true)
  //     setTimeout(()=>{
  //       setPlayed(true)
  //       setTimeout(()=>{
  //         setIntro(false)          
  //       },700)
  //       if(document.getElementById("video-id")){
  //         document.getElementById("video-id").style.opacity='0'
  //       }
  //     }, 8000)
  //   } 
  //   if(sessionStorage.getItem("Played State") !== "VISIBLE"){
  //     if(window.innerWidth < 1024){
  //       setPlayed(true)
  //     }
  //     setTimeout(()=>{
  //       sessionStorage.setItem("Played State", "VISIBLE")
  //       setPlayed(true)
  //     },8000)       
  //   }
  //   if(sessionStorage.getItem("Played State") === "VISIBLE"){
  //     setPlayed(true) 
  //   }
    
  // },[intro, played])

  // const closeIntro = () => {
  //   setTimeout(()=>{
  //     setIntro(false)
  //   },700)
  //   setPlayed(true)
  //   sessionStorage.setItem("Intro State", "PLAYED")  
  //   sessionStorage.setItem("Played State", "VISIBLE")
  //   document.getElementById("video-id").style.opacity='0'
  //   console.log(document.getElementById("video-id"));
  //   setTimeout(()=>{
  //     document.getElementById("video-id").style.display='none'
  //   }, 2000)
  //   document.getElementById("video-button-id").style.opacity='0'
  //   setTimeout(()=>{
  //     document.getElementById("video-button-id").style.display='none'
  //   }, 1000)
  // }
// VIDEO INTRO END

  // CHECK POSITION
  const checkPosition = () => {
    setPosition(window.pageYOffset)
  }
  useEffect(() => {
    window.addEventListener("scroll", checkPosition)
    return () => {
      window.removeEventListener("scroll", checkPosition)
    }
  })
  // CHECK POSITION END
  return (
    <>
      {/* VIDEO INTRO */}
      {/* <div className={intro ? "overlay": "no-video"} id="video-id">
        <div className={intro ? "video-container" : "no-video"} id="video-id">
          <video autoPlay muted> 
            <source src={IntroVideo} type="video/mp4"/>
          </video>
          <button className="intro-btn" id="video-button-id" onClick={closeIntro}>SKIP</button>
        </div>
      </div> */}

      
      {position > 10 && <Link to="/contact"><button data-aos="fade-up" className="start-your-project text-center"><h6 className="text-center">Start Your <br/>Project</h6></button></Link>}

      <div>
        <Layout>

          <Seo title="Three Division Pictures" url="https://www.3dp.vision"/>

          {/* BLOCK 1 */}
          <div className="block1">
            {/*<h1 data-aos="fade-down" className="home-title p-1">"VISION WITHOUT EXECUTION <br/>IS JUST AN ILLUSION"</h1>*/}

              <Carousel id="carousel-home">          
                <Carousel.Item interval={5000}>
                  <img
                    className="d-block w-100"
                    src={slide5}
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className="d-block w-100"
                    src={slide2}
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className="d-block w-100"
                    src={slide3}
                    alt="Third slide"
                  />
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className="d-block w-100"
                    src={slide4}
                    alt="Fourth slide"
                  />
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <img
                    className="d-block w-100"
                    src={slide1}
                    alt="Fifth slide"
                  />
                </Carousel.Item>
            </Carousel>
          </div>
        

          {/* BLOCK 2 */}

          <div className="block2 px-lg-5">
            
            <div data-aos="fade-right" className="block2-text-container px-lg-5 p-md-3">
              <h2 className="text-uppercase">We’re in the business of <br/>bringing visions to life. </h2>  
              <h2 className="invisible m-0 p-0">some</h2>            
              <p>Three Division Pictures is a full-service film production company. It’s our mission to help our clients bring their visions to life and broadcast them for the world to see. With advanced film equipment, cutting-edge innovation, and a world-class team, we have the tools to take your film and videos to the next level. All we need is your vision.</p>
              
              <Link className="Link-link" to="/about">
                <button className="about-us-button">about us</button>
              </Link>        
            </div>

            <StaticImage data-aos="fade-left" className="block2-img-container" src="../data/images/10.jpg" placeholder="blurred" alt="visions"/>

          </div>

          {/* BLOCK 3 */}

          <div className="block4">
            <div className="block4-container">
              <h1 data-aos="fade-down" className="px-2 text-uppercase">"VISION WITHOUT EXECUTION <br/>IS JUST AN ILLUSION"</h1>
              <Link to="/contact" className="Link-link">
                <button data-aos="fade-up" className="about-us-button">contact us</button>
              </Link>
            </div>
          </div>

        </Layout>
      </div>
    </>
  )
}


