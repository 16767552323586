import React from "react";
import { Button, Modal } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

const CardModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <StaticImage src="../data/images/12.jpg" className="modal-img" alt="about" placeholder="blurred"/>
        <button className="play-button">
          <svg viewBox="0 0 60 60" width="60" height="60" className="svg-play-icon">
            <polygon points="0,0 60,30 0,60" />
          </svg>
        </button>
      </div>

      <div className="ourwork-modal">
        <h4>BLOODLINE</h4>
        <br/>
        <p className="pl-0 text-uppercase">
          Short synopsis
        </p>
        <br/>
        <p className="pl-0">
          An orphaned young man, Darko, seeks to revenge his parents murder. He is raised in the underworld and becomes an expert assassin, but over the years the moral weight of the work bears down upon him and he begins to question himself and the direction his life is taking. He is ultimately forced to make a violent choice between his family, his revenge and his identity.
        </p>
        <br/>
        <p className="text-uppercase">Main cast</p>
        <br/>
        <p>
          Dubrovnik | Željko Vukmirica<br/>
          Darko | Rade Radolović<br/>
          Milena | Romina Tonković<br/>
          Father | Enes Vejzović<br/>
          Mother | Bojana Gregorić Vejzović
        </p>
        <br/>
        <p className="text-uppercase">Crew</p>
        <br/>
        <div className="container-fluid p-0">
          <table className="table table-sm custom-table">
            <tbody>
            <tr>
              <td>Director:</td>
              <td>Silvio Jesenković</td>
            </tr>
            <tr>
              <td>Screenwriter:</td>
              <td>Mihovil Rismondo</td>
            </tr>
            <tr>
              <td>Producer:</td>
              <td>Karlo Katulić</td>
            </tr>
            <tr>
              <td>Executive producer:</td>
              <td>Igor A. Nola</td>
            </tr>
            <tr>
              <td>Director of photography:</td>
              <td>Ivan Zadro</td>
            </tr>
            <tr>
              <td>Editor:</td>
              <td>Dejan Bošković</td>
            </tr>
            <tr>
              <td>Sound designer:</td>
              <td>Aleksander Simeonov</td>
            </tr>
            <tr>
              <td>Composer(s):</td>
              <td>Ian Tipping / Stjepan Tribuson</td>
            </tr>
            </tbody>
          </table>
        </div>


      </div>
      <div className="ourwork-modal d-flex justify-content-end">
        <button onClick={props.onHide} className="close-modal-btn "><h6><small className="font-weight-bold">CLOSE</small></h6></button>
      </div>
    </Modal>
  );
};
export default CardModal;
