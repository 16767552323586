import React, {useEffect, useState} from 'react'
import Sidebar from "./Sidebar"
import Impressum from "./Impressum"
import "../styles/global.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Aos from "aos"
import "../styles/home.css"
import 'aos/dist/aos.css'

const Layout = ({children}) => {
  useEffect(()=>{
    Aos.init({duration: 2000, once:true})
  },[])
  const [position, setPosition] = useState(0)

  // BUTTON
  const checkPosition = () => {
    setPosition(window.pageYOffset)
  }
  useEffect(() => {
    window.addEventListener("scroll", checkPosition)
    return () => {
      window.removeEventListener("scroll", checkPosition)
    }
  })

  return (
    <>
      
      <Sidebar position={position}/>       

      {/* PAGES */}

        {children}

      {/* PAGES */}

      <Impressum />
    </>
  )
}

export default Layout
